import { AuthAction, AUTH_ACTION_TYPES } from "../authTypes";

export type LoadingType = 'idle' | 'loading' | 'failed' | string;

type AuthState = {
  loginStatus: LoadingType,
};

const initialState: AuthState = {
  loginStatus: 'idle',
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN_STATUS: {
      return {
        ...state,
        loginStatus: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
