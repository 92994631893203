export const COLORS = {
  pinkPrimary: '#B452DE',
  violetPrimary: '#6d2ef1',
  violetLight: '#7d49ee',
  violetDark: '#48208F',
  blackPrimary: '#000000',
  whitePrimary: '#ffffff',
  whiteSecondary: '#F5F5F5',
  grayPrimary: '#cccccc',
  graySecondary: '#EBEBEB',
  grayTable: '#e0e0e0',
  tableSelected: '#ebebeb',
  blackSecondary: '#666666',
  greenPrimary: '#66A352',
  redPrimary: '#C5283D',
  yellowPrimary: '#FFC100',
  violetSecondary: '#E4D9FC',
  bluePrimary: '#170048',
}