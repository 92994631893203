import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoadingSpinner from "./Components/Loader/LoadingSpinner";
import Header from "./Components/Header";
import ScrollToTop from "./Components/ScrollToTop";

import "./Styles/index.scss";
import "./Styles/account-info.css";
import "./Styles/responsive.css";

import "./Styles/freelancer.css";
import "./Styles/chat.css";
import "./Styles/job.css";
import "./Components/popup.css";
import GoogleTag from "./Components/GoogleTag";
import { Helmet } from "react-helmet";

const Home = lazy(() => import("./Pages/Home"));
const SignUp = lazy(() => import("./Pages/SignUp"));
const PageNotFound = lazy(() => import("./Pages/PageNotFound"));
const Welcome = lazy(() => import("./Pages/Welcome"));
const Learn = lazy(() => import("./Pages/Learn"));
const Course = lazy(() => import("./Pages/Learn/Course"));

const App = () => {
  return (
    <>
      <GoogleTag/>
      <ScrollToTop/>
      <Suspense fallback={<LoadingSpinner/>}>
        <div className="full-height-wrapper">
          <Routes>
            <Route path="/leap" element={<Home/>}/>
            <Route path="/leap/signup/:type" element={<SignUp/>}/>
            <Route path="/leap/welcome" element={<Welcome/>}/>
            <Route path="/leap/learn" element={<Learn/>}/>
            <Route path="/leap/learn/:lesson" element={<Course/>}/>

            <Route path='*' element={<PageNotFound/>} />
          </Routes>
        </div>
      </Suspense>
    </>
  );
}

export default App;
