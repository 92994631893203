import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const GoogleTag = () => {
  const path = useLocation();
  const [url, setUrl] = useState(path?.pathname);

  useEffect(() => {
    setUrl(path?.pathname);
  }, [path]);

  return (
    <Helmet key={url}>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-H6425455ZX"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);console.log(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-H6425455ZX');
      `}
      </script>
    </Helmet>
  );
}

export default GoogleTag;