import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "UI/colors";

const Header = () => {
  return (
    <>
      <WrapperHomeHeader>
        <Navbar collapseOnSelect expand="md" className="external_nav_padding">
          <Container>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <a href="/leap">
                <Navbar.Brand>
                  <img src="/assets/images/logo.svg" className="img-fluid rounded-top" alt="" />
                </Navbar.Brand>
              </a>
            </div>
          </Container>
        </Navbar>
      </WrapperHomeHeader>
    </>
  );
};

export default Header;

export const WrapperHomeHeader = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  
  .nav-item-desktop {
    font-weight: 500;
    color: ${COLORS.whitePrimary};
    margin: 0 8px;
    padding: 8px;
    font-size: 15px;

    &:hover {
      color: ${COLORS.blackPrimary};
    }
  }

  .active_btn {
    word-wrap: normal;
    white-space: nowrap;  
    padding: 10px 20px !important;
  }
`;